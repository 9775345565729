import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import {
  ingredientInputs,
  doughInputs,
  productInputs,
  userInputs,
  ingredientVolumeInputs, // Import volume inputs
  lineInputs,
  productionListInputs,
} from "../../formSource";

import IngredientsForm from "./IngredientsForm";
import DoughsForm from "./DoughsForm";
import ProductsForm from "./ProductsForm";

import { MASS_UNITS, VOLUME_UNITS } from "../../constants";

const New = () => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [doughs, setDoughs] = useState([]);
  const [products, setProducts] = useState([]);
  const [productionLines, setProductionLines] = useState([]);
  //RECIPE STATE INFORMATION
  const [ingredientAmounts, setIngredientAmounts] = useState([]);
  //USED TO TRACK SELECTED UNITS INSIDE RECIPES
  const [selectedUnits, setSelectedUnits] = useState([]);
  //USED TO TRACK SELECTED UNITS FOR CUSTOM INGREDIENT UNITS
  const [selectedIngredientUnits, setSelectedIngredientUnits] = useState([]);
  const [conversionRates, setConversionRates] = useState([]);

  //MIX WEIGHT VALUES FOR DOUGH BATCHES
  const [minMixUnit, setMinMixUnit] = useState(MASS_UNITS[0]);
  const [maxMixUnit, setMaxMixUnit] = useState(MASS_UNITS[0]);

  //Ingredient Units
  const [ingredientUnits, setIngredientUnits] = useState([]);
  //Product Units
  const [productUnits, setProductUnits] = useState([]);

  //PRODUCTION LINES STATE INFORMATION
  const [productsPercentage, setProductsPercentage] = useState([]);
  //PRODUCTION LISTS STATE INFORMATION
  const [linesAndProducts, setLinesAndProducts] = useState([]);

  const navigate = useNavigate();
  const { type } = useParams();
  const organizationId = JSON.parse(localStorage.getItem("organizations"))
    .selected.id;

  const formConfig = {
    ingredients: { inputs: ingredientInputs, title: "Add New Ingredient Form" },
    doughs: { inputs: doughInputs, title: "Add New Doughs Form" },
    products: { inputs: productInputs, title: "Add New Product Form" },
    users: { inputs: userInputs, title: "Add New User Form" },
    lines: { inputs: lineInputs, title: "Add New Production Line Form" },
    productionlists: {
      inputs: productionListInputs,
      title: "Add New Production List Form",
    },
  };

  const fetchItems = (collectionName, stateUpdater) => {
    return onSnapshot(
      query(
        collection(db, collectionName),
        where("organizationId", "==", organizationId)
      ),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          collection: collectionName,
          ...doc.data(),
        }));
        stateUpdater(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const unsubscribeIngredients = fetchItems("ingredients", setIngredients);
    const unsubscribeDoughs = fetchItems("doughs", setDoughs);
    const unsubscribeProducts = fetchItems("products", setProducts);
    const unsubscribeProductionLines = fetchItems("lines", setProductionLines);
    return () => {
      unsubscribeIngredients();
      unsubscribeDoughs();
      unsubscribeProducts();
      unsubscribeProductionLines();
    };
  }, [organizationId]);

  const allItems = [...ingredients, ...doughs, ...products];

  // const handleInput = (e) => {
  //   const { id, value, type, checked } = e.target;

  //   if (type === "checkbox") {
  //     setData({ ...data, [id]: checked });
  //   } else {
  //     setData({ ...data, [id]: value });
  //   }
  // };
  const handleInput = (e) => {
    const { id, value, type, checked } = e.target;
  
    setData({
      ...data,
      [id]: type === "checkbox" ? checked : type === "number" ? parseFloat(value) || 0 : value,
    });
  };
  

  const handleSelectChange = (e, index, stateUpdater) => {
    const { value, dataset } = e.target;
    console.log("dataset.key ", dataset.key);
    switch (dataset.key) {
      case "ingredients":
        const selectedIngredient = allItems.find(
          (ingredient) => ingredient.id === value
        );
        const newIngredientAmounts = [...stateUpdater];
        newIngredientAmounts[index] = {
          id: value,
          amount: "",
          collection: selectedIngredient.collection,
        };
        setIngredientAmounts(newIngredientAmounts);

        const isVolume = selectedIngredient.volume
          ? selectedIngredient.volume
          : false;
        if (isVolume) {
          const newConversionRates = [...conversionRates];
          newConversionRates[index] = selectedIngredient.massconversion;
          setConversionRates(newConversionRates);
        }
        const defaultUnit = isVolume ? VOLUME_UNITS[0] : MASS_UNITS[0];

        const newSelectedUnits = [...selectedUnits];
        newSelectedUnits[index] = { unit: MASS_UNITS[0], isVolume: false };
        setSelectedUnits(newSelectedUnits);
      case "ingredientUnits":
        break;
      default:
        break;
    }
  };

  const handleAddIngredient = () => {
    setIngredientAmounts([
      ...ingredientAmounts,
      { id: "", amount: "", collection: "" },
    ]);
  };

  const handleRemoveItem = (index) => {
    const newIngredientAmounts = [...ingredientAmounts];
    newIngredientAmounts.splice(index, 1);
    setIngredientAmounts(newIngredientAmounts);
    const newSelectedUnits = [...selectedUnits];
    newSelectedUnits.splice(index, 1);
    setSelectedUnits(newSelectedUnits);
    const newConversionRates = [...conversionRates];
    newConversionRates.splice(index, 1);
    setConversionRates(newConversionRates);
  };

  const handleUnitChange = (e, index) => {
    const { value } = e.target;

    let isVolume;
    let unit = VOLUME_UNITS.find((unit) => unit.abbreviation === value);

    if (!unit) {
      unit = MASS_UNITS.find((unit) => unit.abbreviation === value);
      isVolume = false;
    } else {
      isVolume = true;
    }
    console.log("inside unit change:", { unit, isVolume });

    if (unit) {
      const newSelectedUnits = [...selectedUnits];
      newSelectedUnits[index] = { unit, isVolume };
      setSelectedUnits(newSelectedUnits);
    }
  };

  const handleMixUnitChange = (e) => {
    const { value, id } = e.target;
    console.log("id ", id);
    const unit = MASS_UNITS.find((unit) => unit.abbreviation === value);
    if (id === "minMix") {
      setMinMixUnit(unit);
    } else {
      setMaxMixUnit(unit);
    }
  };

  const handleIngredientWeightUnitChange = (e, index) => {
    const { value } = e.target;

    let isVolume = false;
    let unit = VOLUME_UNITS.find((unit) => unit.abbreviation === value);

    if (!unit) {
      unit = MASS_UNITS.find((unit) => unit.abbreviation === value);
      isVolume = false;
    } else {
      isVolume = true;
    }

    if (unit) {
      const newSelectedUnits = [...selectedIngredientUnits];
      console.log("newSelectedUnits ", newSelectedUnits);
      newSelectedUnits[index] = unit;
      setSelectedIngredientUnits(newSelectedUnits);
    }
  };

  const handleMixInput = (e) => {
    let mixValue = 0;
    const { id, value } = e.target;
    if (id === "minMix") {
      mixValue = value * minMixUnit.amount;
    } else {
      mixValue = value * maxMixUnit.amount;
    }

    setData({ ...data, [id]: mixValue });
  };

  const handleAddProduct = () => {
    setProductsPercentage([
      ...productsPercentage,
      { id: "", scrapPercent: "", piecesPerHour: "" },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const newProductsPercentage = [...productsPercentage];
    newProductsPercentage.splice(index, 1);
    setProductsPercentage(newProductsPercentage);
  };

  const handleProductUnitChange = (e, index, field) => {
    const newUnits = [...productUnits];
    newUnits[index][field] = e.target.value;
    setProductUnits(newUnits);
  };

  const handleIngredientUnitChange = (e, index, field) => {
    console.log("e ", e, " index ", index)
    const newUnits = [...ingredientUnits];
    let  amountValue=1
    if (field === "unitWeight") {
      let selectedUnit = MASS_UNITS.find(
        (unit) =>
          unit.abbreviation === selectedIngredientUnits[index].abbreviation
      );
      if (selectedUnit) {
        amountValue = selectedUnit ? selectedUnit.amount : 1;
        // console.log("selectedUnit gere ", selectedIngredientUnits[index].unit.abbreviation, selectedIngredientUnits)
        newUnits[index][field] = e.target.value * amountValue;
      } else {
        selectedUnit = VOLUME_UNITS.find(
          (unit) =>
            unit.abbreviation === selectedIngredientUnits[index].abbreviation
        );
        amountValue = selectedUnit ? selectedUnit.amount : 1;
        newUnits[index][field] = e.target.value * data.massconversion * selectedIngredientUnits[index].amount ;
      }
    } else {
      newUnits[index][field] = e.target.value;
    }

    setIngredientUnits(newUnits);
  };

  const handleAddUnit = () => {
    if (type === "products") {
      setProductUnits([...productUnits, { name: "", pieceCount: "" }]);
    }
    if (type === "ingredients") {
      console.log("Inside ingredientUnits ", ingredientUnits);
      setIngredientUnits([...ingredientUnits, { name: "", unitWeight: "" }]);

      const defaultUnit = data.isVolume ? VOLUME_UNITS[0] : MASS_UNITS[0];

      const newSelectedUnits = [...selectedIngredientUnits, defaultUnit];
      console.log("newSelectedUnits ", newSelectedUnits);
      // newSelectedUnits[newSelectedUnits.length] = { unit: defaultUnit };
      setSelectedIngredientUnits(newSelectedUnits);
      // setSelectedIngredientUnits([selectedIngredientUnits, ...MASS_UNITS[0]])
    }
  };

  const handleRemoveUnit = (index) => {
    if (type === "products") {
      const newUnits = [...productUnits];
      newUnits.splice(index, 1);
      setProductUnits(newUnits);
    }
    if (type === "ingredients") {
      const newUnits = [...ingredientUnits];
      const newSelectedUnits = [...selectedIngredientUnits];
      newUnits.splice(index, 1);
      newSelectedUnits.splice(index, 1);
      setIngredientUnits(newUnits);
      setSelectedIngredientUnits(newSelectedUnits);
    }
  };

  const handleProductSelectChange = (e, index) => {
    const { value } = e.target;
    const newProductsPercentage = [...productsPercentage];
    newProductsPercentage[index].id = value;
    setProductsPercentage(newProductsPercentage);
  };

  const handleProductPercentageChange = (e, index) => {
    const { name, value } = e.target;
    const newProductsPercentage = [...productsPercentage];
    newProductsPercentage[index][name] = value;
    setProductsPercentage(newProductsPercentage);
  };

  const handleAddLine = () => {
    // setLinesAndProducts([...linesAndProducts, { id: "", products: [] }]);
    setLinesAndProducts((prevData) => [...prevData, { id: "", products: [] }]);
  };

  const handleAddProductToLine = (index) => {
    console.log("inside handleAddProductToLine");
    console.log(
      "linesAndProducts ",
      linesAndProducts,
      linesAndProducts[index].products
    );
    let newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts[index].products.push("");
    console.log(newLinesAndProducts);

    setLinesAndProducts(newLinesAndProducts);
  };

  const handleRemoveProductFromLine = (lineIndex, productIndex) => {
    const newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts[lineIndex].products.splice(productIndex, 1);
    setLinesAndProducts(newLinesAndProducts);
  };

  const handleRemoveLine = (index) => {
    const newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts.splice(index, 1);
    setLinesAndProducts(newLinesAndProducts);
  };
  const handleLineSelectChange = (e, index) => {
    const { value } = e.target;
    const newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts[index].id = value;
    setLinesAndProducts(newLinesAndProducts);
  };
  const handleLineProductSelectChange = (e, lineIndex, productIndex) => {
    const { value } = e.target;
    const newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts[lineIndex].products[productIndex] = value;
    setLinesAndProducts(newLinesAndProducts);
  };

  function getItemNameByProductRef(productRef) {
    const [collection, id] = productRef.split("/");

    const item = allItems.find(
      (item) => item.collection === collection && item.id === id
    );

    return item ? item.name : "Item not found";
  }

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      let newData = {
        organizationId: organizationId,
      };
      if (type === "ingredients" || type === "doughs" || type === "products") {
        newData = {
          ...newData,
          ...data,
          ingredients: ingredientAmounts.map(({ id, amount, collection }) => ({
            [`${collection}/${id}`]: amount,
          })),
          ...(type === "ingredients" && {
            ingredientUnits: ingredientUnits.map(({ name, unitWeight }) => ({
              [name]: unitWeight,
            })),
          }),
          ...(type === "products" && { productUnits }),
        };
      } else if (type === "lines") {
        newData = {
          ...newData,
          ...data,
          products: productsPercentage.map((product) => ({
            [`products/${product.id}`]: {
              piecesPerHour: product.piecesPerHour,
              scrapPercent: product.scrapPercent,
            },
          })),
        };
      } else if (linesAndProducts.length > 0 && type === "productionlists") {
        newData = {
          ...newData,
          ...data,
          // linesAndProducts: linesAndProducts,
          linesAndProducts: linesAndProducts.map((line) => ({
            [`lines/${line.id}`]: [...line.products],
          })),
        };
      } else {
        newData = {
          ...data,
        };
      }

      if (type === "users") {
        const res = await createUserWithEmailAndPassword(
          auth,
          data.email,
          data.password
        );
        await setDoc(doc(db, "users", res.user.uid), {
          ...data,
          timeStamp: serverTimestamp(),
        });
      } else {
        const newItemRef = doc(collection(db, type));
        await setDoc(newItemRef, newData);
      }
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="new">
      {console.log("newData ", data)}
      {console.log("ingredientUnits ", ingredientUnits)}
      {console.log("selectedIngredientUnits ", selectedIngredientUnits)}
      {/* {console.log("allItems ", allItems)} */}
      {console.log("ingredientAmounts ", ingredientAmounts)}

      {/* console.log("conversionRates ", conversionRates)}  */}
      {/* {console.log("productionLines ", productionLines)} */}
      {/* {console.log("productsPercentage ", productsPercentage)} */}
      {console.log("selectedUnits ", selectedUnits)}
      {/* {console.log("linesAndProducts ", linesAndProducts)} */}
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{formConfig[type]?.title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {formConfig[type]?.inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                  />
                </div>
              ))}
              {type === "ingredients" && (
                <>
                  <IngredientsForm
                    data={data}
                    ingredientAmounts={ingredientAmounts}
                    handleSelectChange={handleSelectChange}
                    allItems={allItems}
                    selectedUnits={selectedUnits}
                    conversionRates={conversionRates}
                    setIngredientAmounts={setIngredientAmounts}
                    handleUnitChange={handleUnitChange}
                    handleRemoveItem={handleRemoveItem}
                    handleAddIngredient={handleAddIngredient}
                    ingredientUnits={ingredientUnits}
                    handleIngredientUnitChange={handleIngredientUnitChange}
                    handleIngredientWeightUnitChange={
                      handleIngredientWeightUnitChange
                    }
                    handleRemoveUnit={handleRemoveUnit}
                    handleAddUnit={handleAddUnit}
                    ingredientVolumeInputs={ingredientVolumeInputs}
                    handleInput={handleInput}
                    selectedIngredientUnits={selectedIngredientUnits}
                    setIngredientUnits={setIngredientUnits}
                  />
                </>
              )}
              {type === "doughs" && (
                <>
                  <DoughsForm
                    handleMixInput={handleMixInput}
                    handleMixUnitChange={handleMixUnitChange}
                    ingredientAmounts={ingredientAmounts}
                    handleSelectChange={handleSelectChange}
                    allItems={allItems}
                    selectedUnits={selectedUnits}
                    conversionRates={conversionRates}
                    setIngredientAmounts={setIngredientAmounts}
                    handleUnitChange={handleUnitChange}
                    handleRemoveItem={handleRemoveItem}
                    handleAddIngredient={handleAddIngredient}
                  />
                </>
              )}

              {type === "products" && (
                <>
                  <ProductsForm
                    ingredientAmounts={ingredientAmounts}
                    handleSelectChange={handleSelectChange}
                    allItems={allItems}
                    selectedUnits={selectedUnits}
                    conversionRates={conversionRates}
                    setIngredientAmounts={setIngredientAmounts}
                    handleUnitChange={handleUnitChange}
                    handleRemoveItem={handleRemoveItem}
                    handleAddIngredient={handleAddIngredient}
                    handleAddUnit={handleAddUnit}
                    productUnits={productUnits}
                    handleProductUnitChange={handleProductUnitChange}
                    handleRemoveUnit={handleRemoveUnit}
                  />
                </>
              )}

              <br />
              <div></div>
              <br />
              <div></div>
              <br />
              <br />
              <div></div>
              <br />
              <div></div>
              <br />
              <br />
              <div></div>
              <br />
              <div></div>
              <br />
              <br />
              <div></div>
              <br />
              <div></div>
              <br />
              <br />
              {type === "lines" ? (
                <div>
                  <h3>Products run on line:</h3>
                  {productsPercentage.length > 0 ? (
                    <>
                      <table>
                        <th>Product </th>
                        <th>Pieces/Hour </th>
                        <th>Scrap % </th>

                        {productsPercentage.map((product, index) => (
                          <tr key={index}>
                            <td>
                              <select
                                onChange={(e) =>
                                  handleProductSelectChange(e, index)
                                }
                                value={product.id}
                              >
                                <option value="">Select Product</option>
                                {products.map((product, index) => (
                                  <option key={index} value={product.id}>
                                    {product.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                            {productsPercentage[index].id !== "" ? (
                              <>
                                <td>
                                  <input
                                    type="number"
                                    name="piecesPerHour"
                                    value={product.piecesPerHour}
                                    onChange={(e) =>
                                      handleProductPercentageChange(e, index)
                                    }
                                    placeholder="Pieces/Hour"
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="scrapPercent"
                                    value={product.scrapPercent}
                                    onChange={(e) =>
                                      handleProductPercentageChange(e, index)
                                    }
                                    placeholder="Scrap Percentage"
                                  />
                                </td>
                              </>
                            ) : null}
                            <button
                              type="button"
                              onClick={() => handleRemoveProduct(index)}
                            >
                              Remove
                            </button>
                          </tr>
                        ))}
                      </table>
                    </>
                  ) : null}
                  <button type="button" onClick={handleAddProduct}>
                    Add Product
                  </button>
                </div>
              ) : null}

              {type === "productionlists" ? (
                <div>
                  <h3>Production Lines and Products</h3>
                  {linesAndProducts.map((line, lineIndex) => (
                    <div key={lineIndex}>
                      Production Line {lineIndex + 1}
                      <br />
                      <select
                        onChange={(e) => handleLineSelectChange(e, lineIndex)}
                        value={line.id}
                      >
                        <option value="">Select Production Line</option>
                        {productionLines.map((line, optionsIndex) => (
                          <option key={optionsIndex} value={line.id}>
                            {line.name}
                          </option>
                        ))}
                      </select>
                      {linesAndProducts[lineIndex].id !== "" ? (
                        <>
                          <button
                            type="button"
                            onClick={() => handleAddProductToLine(lineIndex)}
                          >
                            Add Product to Line
                          </button>{" "}
                        </>
                      ) : null}{" "}
                      <button
                        type="button"
                        onClick={() => handleRemoveLine(lineIndex)}
                      >
                        Remove
                      </button>
                      <br />
                      {linesAndProducts[lineIndex].id !== "" ? (
                        <>
                          {linesAndProducts[lineIndex].products.map(
                            (product, productIndex) => (
                              <div key={productIndex}>
                                <ul>
                                  Product {productIndex + 1} <br />
                                  <select
                                    onChange={(e) => {
                                      console.log(
                                        "handle change in ",
                                        lineIndex,
                                        " ",
                                        productIndex
                                      );

                                      handleLineProductSelectChange(
                                        e,
                                        lineIndex,
                                        productIndex
                                      );
                                    }}
                                    value={
                                      linesAndProducts[lineIndex].products[
                                        productIndex
                                      ]
                                    }
                                  >
                                    <option value="" key="initial">
                                      Select{" "}
                                    </option>
                                    {productionLines
                                      .find(
                                        (line) =>
                                          line.id ===
                                          linesAndProducts[lineIndex].id
                                      )
                                      .products.map(
                                        (product, productOptionIndex) => {
                                          const productRef =
                                            Object.keys(product)[0];
                                          return (
                                            <option
                                              value={productRef}
                                              key={productOptionIndex}
                                            >
                                              {getItemNameByProductRef(
                                                productRef
                                              )}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveProductFromLine(
                                        lineIndex,
                                        productIndex
                                      )
                                    }
                                  >
                                    Remove
                                  </button>
                                </ul>
                              </div>
                            )
                          )}
                        </>
                      ) : null}
                    </div>
                  ))}
                  <button type="button" onClick={handleAddLine}>
                    Add Production Line
                  </button>
                </div>
              ) : null}

              <div>
                <button disabled={per !== null && per < 100} type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;

import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { ingredientColumns, doughColumns, userColumns, productColumns, lineColumns, productionListColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

const Datatable = ({ title, type }) => {
  const [data, setData] = useState([]);
  const organizationId = JSON.parse(localStorage.getItem("organizations"))
    .selected.id;

  const columnsConfig = {
    ingredients: { columns: ingredientColumns },
    doughs: { columns: doughColumns },
    products: { columns: productColumns },
    users: { columns: userColumns },
    lines: { columns: lineColumns },
    productionlists: { columns: productionListColumns },
  };
  //console.log("type ", type);

  useEffect(() => {
    // const fetchData = async () => {
    //   let list = [];
    //   try {
    //     const querySnapshot = await getDocs(collection(db, "users"));
    //     querySnapshot.forEach((doc) => {
    //       list.push({ id: doc.id, ...doc.data() });
    //     });
    //     setData(list);
    //     console.log(list);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };
    // fetchData();

    // LISTEN (REALTIME)
    // const unsub = onSnapshot(
    //   collection(db, type),
    //   (snapShot) => {
    //     let list = [];
    //     snapShot.docs.forEach((doc) => {
    //       list.push({ id: doc.id, ...doc.data() });
    //     });
    //     console.log(list)
    //     setData(list);
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );

    const unsub = onSnapshot(
      query(
        collection(db, type),
        where("organizationId", "==", organizationId)
      ),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        //console.log(list);
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, [type, organizationId]);
  //console.log(data);

  const updateReferences = async (deletedId, deletedType) => {
    const collections = ['ingredients', 'doughs', 'products']; // Collections to update
    const deletedRef = `${deletedType}/${deletedId}`;
    console.log("deletedRef", deletedRef);

    for (const col of collections) {
      const q = query(collection(db, col), where("organizationId", "==", organizationId));
      const snapshot = await getDocs(q);

      snapshot.forEach(async (document) => {
        const docRef = doc(db, col, document.id);
        const data = document.data();

        const updatedIngredients = data.ingredients.filter(ingredientMap => {
          const ingredientId = Object.keys(ingredientMap)[0];
          return ingredientId !== deletedRef;
        });

        if (updatedIngredients.length !== data.ingredients.length) {
          await updateDoc(docRef, { ingredients: updatedIngredients });
        }
      });
    }
    updateProductionLines(deletedId, deletedType);
    updateProductionLists(deletedId, deletedType);
  };

  const updateProductionLines = async (deletedId, deletedType) => {
    const collections = ['lines']; // Collections to update
    const deletedRef = `${deletedType}/${deletedId}`;
    console.log("deletedRef", deletedRef);

    for (const col of collections) {
      const q = query(collection(db, col), where("organizationId", "==", organizationId));
      const snapshot = await getDocs(q);

      snapshot.forEach(async (document) => {
        const docRef = doc(db, col, document.id);
        const data = document.data();

        const updatedProducts = data.products.filter(ingredientMap => {
          const productId = Object.keys(ingredientMap)[0];
          return productId !== deletedRef;
        });

        if (updatedProducts.length !== data.products.length) {
          await updateDoc(docRef, { products: updatedProducts });
        }
      });
    }
  };

  const updateProductionLists = async (deletedId, deletedType) => {
    const collections = ['productionlists']; // Collections to update
    const deletedRef = `${deletedType}/${deletedId}`;

    for (const col of collections) {
      const q = query(collection(db, col), where("organizationId", "==", organizationId));
      const snapshot = await getDocs(q);

      snapshot.forEach(async (document) => {
        const docRef = doc(db, col, document.id);
        const data = document.data();

        const updatedLinesAndProducts = data.linesAndProducts.filter(linesAndProductMap => {
          const lineId = Object.keys(linesAndProductMap)[0];
          return lineId !== deletedRef;
        });

        if (updatedLinesAndProducts.length !== data.linesAndProducts.length) {
          await updateDoc(docRef, { linesAndProducts: updatedLinesAndProducts });
        } else {

          // Update linesAndProducts to remove deleted line or product
          const updatedLinesAndProducts = data.linesAndProducts.map(linesAndProductsMap => {
            const lineId = Object.keys(linesAndProductsMap)[0];
            const products = linesAndProductsMap[lineId].filter(productId => productId !== deletedRef);

            if (products.length !== linesAndProductsMap[lineId].length) {
              return {
                [lineId]: products
              };
            }
            return linesAndProductsMap;
          });

          // Check if any change was made before updating the document
          if (updatedLinesAndProducts.some(map => Object.keys(map)[0] !== deletedRef)) {
            await updateDoc(docRef, { linesAndProducts: updatedLinesAndProducts });
          }
        }

      });
    }
    // for (const col of collections) {
    //   const q = query(collection(db, col), where("organizationId", "==", organizationId));
    //   const snapshot = await getDocs(q);

    //   snapshot.forEach(async (document) => {
    //     const docRef = doc(db, col, document.id);
    //     const data = document.data();

    //     // Update linesAndProducts to remove deleted line or product
    //     const updatedLinesAndProducts = data.linesAndProducts.map(linesAndProductsMap => {
    //       const lineId = Object.keys(linesAndProductsMap)[0];
    //       const products = linesAndProductsMap[lineId].filter(productId => productId !== deletedRef);

    //       if (products.length !== linesAndProductsMap[lineId].length) {
    //         return {
    //           [lineId]: products
    //         };
    //       }
    //       return linesAndProductsMap;
    //     });

    //     // Check if any change was made before updating the document
    //     if (updatedLinesAndProducts.some(map => Object.keys(map)[0] !== deletedRef)) {
    //       await updateDoc(docRef, { linesAndProductsMap: updatedLinesAndProducts });
    //     }
    //   });
    // }


  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (confirmDelete) {
      try {
        await updateReferences(id, type); // Remove references first
        await deleteDoc(doc(db, type, id));
        setData(data.filter((item) => item.id !== id));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/${type}/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        {title.charAt(0).toUpperCase() + title.slice(1)}
        <Link to={`/${type}/new`} className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columnsConfig[type].columns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;